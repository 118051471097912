<template>
  <div class="main-container">
    <el-breadcrumb separator="/" class="breadcrumb" style="margin:0.6rem;display: inline-block;">
      <el-breadcrumb-item style="">资产管理</el-breadcrumb-item>
      <el-breadcrumb-item>{{page_type==='approve' && isApprove ? '返厂审批' : (page_type=='wl' && $route.query.instrument_status=='已维修') ? '维修出厂' : formData.instrument_status_name == '出厂中' ? '出厂详情' :  '返厂详情'}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-link size="mini" type="primary"  icon="el-icon-back" class="back-btn" @click="goBack">返回上一页</el-link>
    <div class="content-block">
      <am-detail :isBreadcrumb="false" :isMapShow="false" @getForm="getFormData"></am-detail>
      <div class="content-title  separate-line">
        <p>返厂申请</p>
      </div>
      <div>
        <el-descriptions title="" :column="1" :colon="false">
          <el-descriptions-item label="原因说明：">{{form.remarks}}</el-descriptions-item>
          <el-descriptions-item label="相关附件：">
<!--            <el-image-->
<!--                v-for="(item, index) in form.attachments"-->
<!--                :key="Math.random()"-->
<!--                style="width: 50px; height: 50px; margin-right: 10px"-->
<!--                :preview-src-list="[item.attachment_url ? item.attachment_url :'']"-->
<!--                :src="item.attachment_url ? item.attachment_url :''"-->
<!--                :fit="fit"-->
<!--            ></el-image>-->
              <div v-for="(item, index) in form.attachments" :key="Math.random()">
                <span style="margin-right: 10px" >{{item.attachment_url.split('/')[item.attachment_url.split('/').length - 1] }}</span>
                <el-link type="primary" @click="download(item.attachment_url)" >下载</el-link>
              </div>

          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
    <div class="content-block" >
      <div v-if="page_type==='approve' && isApprove">
        <div class="content-title  separate-line">
          <p>审批流程</p>
        </div>
        <div>
          <el-input type="textarea" :rows="3" placeholder="请输入审批意见" v-model="remarks"></el-input>
          <el-button size="small" type="primary" class="approval-button" @click="submit(1)">同意</el-button>
          <el-button size="small" type="info"  class="approval-button" @click="submit(0)">驳回</el-button>
        </div>
      </div>
      <div class="content-title  separate-line">
        <p>流转意见</p>
      </div>
      <div class="renturn-suggest">
        <ul>
          <li v-for="item in suggestList" class="item-block">
            <div class="left-column">
              <p class="left" style="color: #2D74B6">{{item.send_user_name}}</p>
              <p class="left">{{item.send_user_org}}</p>
            </div>
            <div class="right-column">
              <p class="right">接收人：{{item.receive_user}}</p>
              <p class="right" v-if="item.opt_agreeable_unique == '驳回'">驳回意见：{{item.remarks}}</p>
              <p class="right">{{item.create_time}}【{{item.opt_agreeable_unique}}】</p>
              </div>
          </li>
        </ul>
      </div>
      <logistics-check v-if="['wl','wl-check'].includes(page_type)"
                       :express_no="formData.express_no ? formData.express_no :''"
                       :express_com="formData.express_com ? formData.express_com :''" :instrument_status="form.instrument_status"
                       :selfType="$route.query.selfType"></logistics-check>
    </div>
  </div>
</template>
<script>
import AssetsDetail from "@/views/AssetManagement/components/assetsDetail.vue";
import AmDetail from "@/views/AssetManagement/amDetail.vue";
import logisticsCheck from "@/views/AssetManagement/components/logisticsCheck.vue";
import {applyDetail, approvalApplication} from "@/api/assetMgt";
import TJDetailTitle from "@/components/TJDetailTitle.vue";
import assetCommon from "@/views/AssetManagement/mixin/assetCommon";

export default {
  name: 'applyMaintenance', // 申请维修
  components: {AmDetail, TJDetailTitle, logisticsCheck, AssetsDetail},
  mixins: [assetCommon],
  data(){
    return{
      page_type: this.$route.query.page_type, // 页面类型：wl-显示物流信息，approve-显示审批信息，check-显示查看信息（不显示物流和审批信息）
      form: {
        remarks:'',
      },
      formData: {},
      fit:'',
      remarks: '',
      suggestList:[],
      isApprove: false, //审批接收人是当前登录人显示审批模块
    }
  },
  created() {
    this.getDetail()
  },
  methods:{
    getFormData(val){
      this.formData = val
    },
    getDetail(){
      applyDetail({instrument_id:this.$route.query.instrument_id,is_submitted: 1}).then(res =>{
        this.form = Object.assign(this.form,res.data.note_info)
        let process_info = res.data.process_info
        let approval_info = res.data.approval_info
        if(process_info && approval_info && (process_info.length == approval_info.length)){
          // 融合数组
          const fusedArray = [];
          for (let i = 0; i < process_info.length; i++) {
            // 将两个对象融合成一个新的对象，使用展开操作符或者手动赋值
            const fusedObject = {
              ...process_info[i], // 融合 process_info 的对象
              ...approval_info[i] // 融合 approval_info 的对象
              // 如果有重名键，后面的对象会覆盖前面的对象中的值
            };
            fusedArray.push(fusedObject);
          }
          this.suggestList = fusedArray
        }
        let currentUserId = JSON.parse(localStorage.getItem("userInfo")).user_id
        let currentUserName = JSON.parse(localStorage.getItem("userInfo")).user_name
        // 审批中，当接收人=当前人 展示审批流程，并且当审批驳回给提交人 接收人是提交人 不能显示审批流程（填写审批意见，提交审批意见）
        if(this.suggestList[0].receive_uid == currentUserId && this.suggestList[this.suggestList.length -1].send_user_name != currentUserName){
          this.isApprove = true
        }
      })
    },
    submit(type){
      let params
        if( this.remarks) {
          params = {
            note_id: this.form.note_id,
            opt_agreeable: type,
            remarks: this.remarks
          }
        }else{
          if( type == 0){
            this.$message.error('请填写驳回意见！')
            return false
          }else{
            params = {
              note_id: this.form.note_id,
              opt_agreeable: type,
            }
          }
        }
      let loading = this.$loading({
        lock: true,
        text: type ? '提交中...' :'保存中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      approvalApplication(params).then(res=>{
        if(res.code == 200){
          this.$message.success('审批成功！')
          loading.close();
          this.$router.push('/assetMgt/list')
        }
      }).finally(err=>{
        loading.close();
      })
    },
    download(url){
      this.downloadFile(url)
    },
    goBack(){
      this.$router.push({ path: '/assetMgt/list' })
    },
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/asset';

.approval-button{
  padding:8px 30px;
  margin: 20px 20px 20px 0;
}
@media screen and (min-width: 768px) and (max-width: 1550px), print {
  //.renturn-suggest ul li.item-block .left-column  p{
  //  font-size: 14px;
  //}
}
@media screen and (min-width: 1551px) , print {

}
::v-deep .el-descriptions-item__container{
  color: #000;
}
.zone1{
  padding: 0;
}
//关于详情

</style>